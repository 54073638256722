var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" What percentage of known chemicals have safety data sheets (SDS)? "),_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"items":_vm.items1,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" Who creates SDS? "),_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"items":_vm.items2,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" What GHS components are incorporated in SDS? "),_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"items":_vm.items3,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" Because SDS "),_c('b',[_vm._v(" [A] ")]),_vm._v(" legally required to be accurate, one should "),_c('b',[_vm._v(" [B] ")]),_vm._v(". ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" [A]: "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items4,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [B]: "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items5,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" SDS are written for "),_c('b',[_vm._v(" [C] ")]),_vm._v(" setting, because of this, chemicals, hazards, and personal protective equipment requirements may be "),_c('b',[_vm._v(" [D] ")]),_vm._v(". However, the SDS is like wikipedia, it's a good place to start. ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" [C]: "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items6,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1),_c('p',{staticClass:"mb-2"},[_vm._v(" [D]: "),_c('v-select',{staticStyle:{"display":"inline-block","max-width":"150px"},attrs:{"items":_vm.items7,"item-text":"text","item-value":"value","label":"Select option"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }