<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What percentage of known chemicals have safety data sheets (SDS)?
        <v-select
          v-model="inputs.input1"
          style="max-width: 150px"
          :items="items1"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        Who creates SDS?
        <v-select
          v-model="inputs.input2"
          style="max-width: 150px"
          :items="items2"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        What GHS components are incorporated in SDS?
        <v-select
          v-model="inputs.input3"
          style="max-width: 150px"
          :items="items3"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        Because SDS <b>&nbsp;[A]&nbsp;</b> legally required to be accurate, one should
        <b>&nbsp;[B]&nbsp;</b>.
      </p>
      <p class="mb-1">
        [A]:
        <v-select
          v-model="inputs.input4"
          style="display: inline-block; max-width: 150px"
          :items="items4"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p class="mb-2">
        [B]:
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; max-width: 150px"
          :items="items5"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        SDS are written for <b>&nbsp;[C]&nbsp;</b> setting, because of this, chemicals, hazards, and
        personal protective equipment requirements may be <b>&nbsp;[D]&nbsp;</b>. However, the SDS
        is like wikipedia, it's a good place to start.
      </p>
      <p class="mb-1">
        [C]:
        <v-select
          v-model="inputs.input6"
          style="display: inline-block; max-width: 150px"
          :items="items6"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
      <p class="mb-2">
        [D]:
        <v-select
          v-model="inputs.input7"
          style="display: inline-block; max-width: 150px"
          :items="items7"
          item-text="text"
          item-value="value"
          label="Select option"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LCQ2S1Q8',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
      items1: [
        {text: '$35\\,\\%$', value: 'thirtyFive'},
        {text: '$0.1\\,\\%$', value: 'pointOne'},
        {text: '$1\\,\\%$', value: 'one'},
        {text: '$64\\,\\%$', value: 'sixtyFour'},
        {text: '$91\\,\\%$', value: 'ninetyOne'},
        {text: '$10\\,\\%$', value: 'ten'},
        {text: '$15\\,\\%$', value: 'fifteen'},
      ],
      items2: [
        {text: 'chemical suppliers', value: 'chemical'},
        {text: 'government agencies', value: 'government'},
        {text: 'industrial corporations', value: 'industrial'},
        {text: 'universities', value: 'universities'},
      ],
      items3: [
        {text: 'signal words', value: 'signal'},
        {text: 'hazard ratings', value: 'ratings'},
        {text: 'precautionary statements', value: 'precaution'},
        {text: 'hazard statements', value: 'statements'},
        {text: 'pictograms', value: 'pictograms'},
        {text: 'two of these', value: 'two'},
        {text: 'three of these', value: 'three'},
        {text: 'all of these', value: 'all'},
      ],
      items4: [
        {text: 'Are', value: 'are'},
        {text: 'Are not', value: 'areNot'},
      ],
      items5: [
        {text: 'handle chemicals without SDS carefully', value: 'handle'},
        {text: 'examine SDSs from different manufacturers', value: 'examine'},
        {text: 'only read 1 version', value: 'read'},
        {text: 'completely trust the information', value: 'trust'},
      ],
      items6: [
        {text: 'an industrial', value: 'industrial'},
        {text: 'a medical', value: 'medical'},
        {text: 'a laboratory course', value: 'course'},
        {text: 'a research laboratory', value: 'lab'},
      ],
      items7: [
        {text: 'just right', value: 'right'},
        {text: 'overstated', value: 'over'},
        {text: 'understated', value: 'under'},
      ],
    };
  },
};
</script>
<style scoped></style>
